<template>
  <div>
    <el-dialog
      title="行动号召"
      :visible="actionTypeMixShow"
      width="800px"
      @close="close"
      @open="openInit"
      append-to-body
    >
      <div class="localsList mix-wrap-action">
        <p class="batch-p">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-select
            v-model="comVal"
            placeholder="请选择行动号召"
            filterable
            :disabled="!checkedDatas.length"
            @change="(val) => batchChange('action_type', val)"
          >
            <el-option
              :label="action.desc"
              :value="action.key"
              v-for="action in initData.ad.CallToActionType"
              :key="action.key"
            ></el-option>
          </el-select>
        </p>
        <el-checkbox-group
          v-model="checkedDatas"
          @change="
            (val) =>
              handleCheckedDatasChange('action_type', actionTypeMsgList, val)
          "
        >
          <el-checkbox v-for="item in dataList" :label="item.id" :key="item.id">
            <el-tooltip v-if="item.name" :content="item.name">
              <span class="mixName">{{ item.name }}</span></el-tooltip
            >
            <el-select
              placeholder="行动号召"
              type="text"
              filterable
              v-model="item.action_type"
            >
              <el-option
                :label="action.desc"
                :value="action.key"
                v-for="action in initData.ad.CallToActionType"
                :key="action.key"
              ></el-option>
            </el-select>
          </el-checkbox>
        </el-checkbox-group>
        <!-- <el-row :gutter="10" v-for="(item) in dataList" :key="item.id">
                    <el-col :span="10" style="line-height:40px">
                        <span class="mixName">{{item.name}}</span>
                    </el-col>
                    <el-col :span="14">
                         <el-select placeholder="行动号召" type="text" v-model="item.action_type">
                    <el-option :label="action.desc" :value="action.key" v-for="(action) in initData.ad.CallToActionType" :key="action.key"></el-option>
                </el-select>
                    </el-col>
                </el-row>  -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import mixBatchOperate from "@/views/adManagement/assets/js/mixBatchOperate";
export default {
  mixins: [mixBatchOperate],
  props: ["actionTypeMixShow", "actionTypeMsgList"],
  data() {
    return {
      dataList: JSON.parse(JSON.stringify(this.actionTypeMsgList)),
      initData: this.$store.getters.initData,
    };
  },
  methods: {
    close() {
      this.backToInit(this.actionTypeMsgList);
      this.$emit("update:actionTypeMixShow", false);
    },
    openInit() {},
    sure() {
      if (this.dataList.some((v) => !v.action_type)) {
        this.$message({
          type: "warning",
          message: "行动号召不能为空",
        });
        return false;
      }
      this.$emit("setMixMsg", "actionType", this.dataList);
      this.close();
    },
  },
  watch: {
    actionTypeMsgList: {
      handler() {
        this.dataList = JSON.parse(JSON.stringify(this.actionTypeMsgList));
      },
    },
    deep: true,
  },
};
</script>
<style lang="scss" scope>
.localsList {
  max-height: 700px;
  overflow-x: hidden;
  overflow-y: auto;
  .el-row {
    margin-bottom: 10px;
  }
}
</style>
<style lang="scss">
.mix-wrap-action {
  .batch-p {
    display: flex;
    align-items: center;
    .el-select {
      margin-left: 10px;
    }
  }
  .el-checkbox-group {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .el-checkbox {
      width: 95%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
    .el-checkbox__label {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .el-select {
      width: 300px !important;
    }
    .mixName {
      width: 500px;
    }
  }
}
</style>