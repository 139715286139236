<template>
    <div>
        <el-form :model="actionInfo" ref="ruleForm" label-width="140px" class="demo-ruleForm">
            <el-form-item label="行动号召：" prop="page" class="pageSelect" require>
                <el-select placeholder="行动号召" type="text" v-model="actionInfo.action_type" @change="setMsg" v-if="initData">
                    <el-option :label="action.desc" :value="action.key" v-for="(action) in callToActions" :key="action.key"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    export default {
        props:['actionTypeMsg','callToActions'],
        data () {
            return {
                actionInfo:{...this.actionTypeMsg},
                initData:this.$store.getters.initData
            }
        },
        components:{
        },
        methods: {
            setMsg(){
                this.$emit('getActionTypeMsg',this.actionInfo)
            },
        },
        watch: {
            actionTypeMsg:{
                handler(){
                    this.actionInfo = {...this.actionTypeMsg}
                },
                deep:true
            },
        }
    }
</script>
<style lang="scss">
    
</style>